import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import LogoWhite from '../../images/logo/Alstom-Logo.white.png';
import Logo from '../../images/logo/Alstom-Logo.wine.png';
import { toast } from 'react-toastify';
import axios from 'axios';
import DarkModeSwitcher from '../../components/Header/DarkModeSwitcher';
import {jwtDecode} from "jwt-decode";
import {User} from "../../types";

const PaasswordReset: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secureKey, setSecureKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasAlreadyGetPasswordResetKey, setHasAlreadyGetPasswordResetKey] = useState(false);

  const handleSendResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    // if the user has already have the secure key that will be send by mail and we will use it to search the user
    // and reset the password
    if(hasAlreadyGetPasswordResetKey) {
      handleResetPassword(e);
      return;
    }

    try {
        setLoading(true);

        const response = await axios.post('/send-reset-password', {
          email
        });

        if(response.status === 200) {
          toast.success('Email de réinitialisation du mots de passe à bien été envoyé.', {
            autoClose: 2000,
          });
            setHasAlreadyGetPasswordResetKey(true);
          setLoading(false);
        }


    } catch (error) {
      console.error(error);
      toast.error('L\'email de réinitialisation n\'a pas pu être envoyé, veuillez réessayer.');
      setLoading(false);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await axios.post('/reset-my-password', {
        email,
        secureKey,
        password
      });

      if(response.status === 200) {
        toast.success('Votre mot de passe à bien été réinitialisé, veuillez vous connecter désormais.', {
          autoClose: 2000,
        });

        setTimeout(() => {
            window.location.href = '/login';
        }, 2000);

        setLoading(false);
      }

    } catch (error) {
      console.error(error);
      toast.error('Le mot de passe n\'a pas pu être réinitialisé, veuillez réessayer.');
      setLoading(false);
    }
  }

  return (
    <>
      <Breadcrumb pageName="Réinitialisation de votre mot de passe" />

      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex items-center justify-end gap-3 2xsm:gap-7 mt-4 mr-4">
          <ul className="flex items-center gap-2 2xsm:gap-4">
            <DarkModeSwitcher />
          </ul>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="hidden w-full xl:block xl:w-1/2">
            <div className="py-16 px-26 text-center">
              <img
                className="hidden dark:block -mt-24"
                src={LogoWhite}
                alt="Logo"
              />
              <img className="dark:hidden -mt-24" src={Logo} alt="Logo" />

              <span className=" inline-block">
                <svg
                  width="322.74"
                  height="349.68"
                  viewBox="0 0 322.74 349.68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#062173"
                    d="M33.58,294.84l-3.08-12.12c-5.45-2.31-11.03-4.31-16.71-5.99l-.39,5.63-1.57-6.21c-7.04-2.01-11.83-2.9-11.83-2.9,0,0,6.47,24.59,20.04,43.39l15.82,2.78-12.29,1.77c1.71,2.05,3.54,4,5.49,5.82,19.75,18.31,41.74,26.72,49.12,18.77,7.38-7.95-2.64-29.24-22.38-47.55-6.12-5.68-13.81-10.23-21.51-13.82l-.72,10.43Z"
                  />
                  <path
                    fill="#DC3223"
                    d="M62.83,281.68l3.64-11.96c-3.47-4.79-7.21-9.39-11.21-13.76l-3.25,4.62,1.86-6.13c-4.99-5.36-8.63-8.59-8.63-8.59,0,0-7.18,24.39-5.29,47.5l12.1,10.55-11.44-4.83c.4,2.64.97,5.25,1.69,7.82,7.43,25.88,21.91,44.43,32.34,41.44,10.43-2.99,12.87-26.39,5.44-52.27-2.3-8.02-6.53-15.89-11.26-22.94l-6.01,8.56Z"
                  />
                  <path
                    fill="#e6e6e6"
                    d="M243.68,82.92h-1.92V30.4c0-3.99-.79-7.94-2.31-11.63-1.53-3.69-3.77-7.04-6.59-9.86-2.82-2.82-6.18-5.06-9.87-6.59-3.69-1.53-7.65-2.31-11.64-2.31h-111.35c-8.07,0-15.81,3.2-21.51,8.9-5.7,5.7-8.91,13.43-8.91,21.49v288.12c0,3.99.79,7.95,2.32,11.63,1.53,3.69,3.77,7.04,6.59,9.86,2.82,2.82,6.18,5.06,9.87,6.59,3.69,1.53,7.65,2.31,11.64,2.31h111.35c8.07,0,15.8-3.2,21.51-8.9,5.71-5.7,8.91-13.43,8.91-21.49V120.3h1.92v-37.38Z"
                  />
                  <path
                    fill="#fff"
                    d="M212.57,7.91h-14.53c.67,1.64.92,3.41.74,5.17s-.79,3.45-1.78,4.92c-.99,1.47-2.32,2.67-3.88,3.5-1.56.83-3.3,1.27-5.07,1.27h-63.79c-1.77,0-3.51-.44-5.08-1.27-1.56-.83-2.89-2.03-3.88-3.5-.99-1.47-1.6-3.16-1.78-4.92-.18-1.76.07-3.54.74-5.17h-13.58c-6.02,0-11.8,2.39-16.06,6.65-4.26,4.26-6.65,10.03-6.65,16.05v287.7c0,6.02,2.39,11.8,6.65,16.05s10.04,6.65,16.06,6.65h111.89c6.02,0,11.8-2.39,16.06-6.65,4.26-4.26,6.65-10.03,6.65-16.05V30.61c0-6.02-2.39-11.79-6.65-16.05-4.26-4.26-10.04-6.65-16.06-6.65Z"
                  />
                  <path
                    fill="#ccc"
                    d="M190.02,158.29h-66.81c-.64,0-1.25-.25-1.7-.7-.45-.45-.7-1.06-.7-1.69v-66.76c0-.64.25-1.24.7-1.69.45-.45,1.06-.7,1.7-.7h66.81c.64,0,1.25.25,1.7.7.45.45.7,1.06.7,1.69v66.76c0,.63-.25,1.24-.7,1.69s-1.06.7-1.7.7ZM123.21,87.69c-.38,0-.75.15-1.02.42-.27.27-.42.64-.42,1.02v66.76c0,.38.15.75.42,1.02.27.27.64.42,1.02.42h66.81c.38,0,.75-.15,1.02-.42.27-.27.42-.63.42-1.02v-66.76c0-.38-.15-.75-.42-1.02-.27-.27-.64-.42-1.02-.42h-66.81Z"
                  />
                  <path
                    fill="#ccc"
                    d="M204.93,209.46h-102.47v.96h102.47v-.96Z"
                  />
                  <path
                    fill="#062173"
                    d="M105.71,203.48c1.79,0,3.24-1.45,3.24-3.23s-1.45-3.23-3.24-3.23-3.24,1.45-3.24,3.23,1.45,3.23,3.24,3.23Z"
                  />
                  <path
                    fill="#ccc"
                    d="M204.93,241.8h-102.47v.96h102.47v-.96Z"
                  />
                  <path
                    fill="#062173"
                    d="M105.71,235.81c1.79,0,3.24-1.45,3.24-3.23s-1.45-3.23-3.24-3.23-3.24,1.45-3.24,3.23,1.45,3.23,3.24,3.23Z"
                  />
                  <path
                    fill="#062173"
                    d="M203.06,278.62h-32.38c-.56,0-1.1-.22-1.49-.62-.4-.39-.62-.93-.62-1.49v-11.34c0-.56.22-1.1.62-1.49.4-.4.93-.62,1.49-.62h32.38c.56,0,1.1.22,1.49.62.4.39.62.93.62,1.49v11.34c0,.56-.22,1.1-.62,1.49-.39.4-.93.62-1.49.62Z"
                  />
                  <path
                    fill="#062173"
                    d="M116.26,203.48c1.79,0,3.24-1.45,3.24-3.23s-1.45-3.23-3.24-3.23-3.24,1.45-3.24,3.23,1.45,3.23,3.24,3.23Z"
                  />
                  <path
                    fill="#062173"
                    d="M128.22,203.16c1.61-.78,2.29-2.71,1.51-4.32s-2.71-2.28-4.32-1.51-2.29,2.71-1.51,4.32,2.71,2.28,4.32,1.51Z"
                  />
                  <path
                    fill="#062173"
                    d="M116.26,235.81c1.79,0,3.24-1.45,3.24-3.23s-1.45-3.23-3.24-3.23-3.24,1.45-3.24,3.23,1.45,3.23,3.24,3.23Z"
                  />
                  <path
                    fill="#062173"
                    d="M126.82,235.81c1.79,0,3.24-1.45,3.24-3.23s-1.45-3.23-3.24-3.23-3.24,1.45-3.24,3.23,1.45,3.23,3.24,3.23Z"
                  />
                  <path
                    fill="#ffb8b8"
                    d="M264.74,229.31c.23.1.45.23.66.37l21.03-8.97.75-5.54,8.4-.05-.5,12.69-27.75,7.34c-.06.19-.13.38-.21.57-.4.86-1.05,1.57-1.85,2.06-.81.49-1.74.73-2.69.69-.94-.04-1.86-.36-2.62-.91-.77-.56-1.35-1.32-1.68-2.21-.33-.89-.39-1.85-.17-2.77.22-.92.7-1.76,1.39-2.4.69-.65,1.56-1.07,2.49-1.23.93-.16,1.89-.03,2.75.35Z"
                  />
                  <path
                    fill="#ffb8b8"
                    d="M298.64,344.35h-5.75l-2.73-22.15h8.48v22.15Z"
                  />
                  <path
                    fill="#062173"
                    d="M288.79,342.71h11.08v6.97h-18.06c0-1.85.74-3.62,2.04-4.93,1.31-1.31,3.08-2.04,4.93-2.04Z"
                  />
                  <path
                    fill="#ffb8b8"
                    d="M320.99,342.73l-5.72.56-4.89-21.78,8.44-.83,2.17,22.05Z"
                  />
                  <path
                    fill="#062173"
                    d="M311.03,342.06l11.03-1.09.68,6.94-17.98,1.77c-.09-.91,0-1.83.27-2.71.27-.88.7-1.69,1.28-2.4.58-.71,1.3-1.29,2.11-1.73.81-.43,1.69-.7,2.61-.79Z"
                  />
                  <path
                    fill="#ffb8b8"
                    d="M300.24,191.68c6.36,0,11.51-5.15,11.51-11.51s-5.16-11.51-11.51-11.51-11.52,5.15-11.52,11.51,5.15,11.51,11.52,11.51Z"
                  />
                  <path
                    fill="#062173"
                    d="M291.61,339.87c-.49,0-.97-.17-1.35-.49-.38-.32-.63-.76-.72-1.24-2.97-16.5-12.7-70.46-12.93-71.96,0-.03,0-.07,0-.1v-4.02c0-.15.05-.29.13-.41l1.28-1.8c.06-.09.14-.16.23-.21.09-.05.2-.08.3-.09,7.32-.34,31.31-1.35,32.47.1,1.16,1.45.75,5.86.66,6.73v.09s10.78,68.87,10.78,68.87c.08.55-.05,1.11-.38,1.56-.33.45-.82.75-1.36.85l-6.73,1.11c-.5.08-1.01-.02-1.45-.29-.43-.27-.76-.68-.91-1.16-2.08-6.65-9.06-29.01-11.48-37.66-.02-.05-.05-.1-.1-.13-.05-.03-.1-.04-.16-.04-.06,0-.11.04-.15.08-.04.04-.06.1-.06.15.12,8.25.41,29.29.51,36.56v.78c.01.53-.18,1.04-.54,1.44-.36.39-.85.64-1.37.69l-6.49.59c-.07,0-.13,0-.2,0Z"
                  />
                  <path
                    fill="#dc3223"
                    d="M292.93,196.2c-2.01,1.19-3.21,3.39-3.9,5.62-1.28,4.13-2.05,8.4-2.29,12.72l-.73,12.93-9.03,34.28c7.82,6.61,12.34,5.11,22.87-.3,10.53-5.41,11.74,1.81,11.74,1.81l2.11-29.17,3.01-31.87c-.67-.81-1.44-1.55-2.28-2.19-2.78-2.16-6.02-3.66-9.46-4.39-3.45-.73-7.01-.67-10.43.18l-1.6.39Z"
                  />
                  <path
                    fill="#ffb8b8"
                    d="M290,236.23c.24.09.48.2.7.33l20.79-10.4.35-5.63,8.58-.59.46,12.84-27.79,9.18c-.23.97-.75,1.85-1.48,2.52-.74.67-1.67,1.1-2.66,1.23-.99.13-2-.04-2.89-.49-.89-.45-1.62-1.17-2.1-2.05-.47-.88-.67-1.88-.56-2.87.11-.99.52-1.93,1.17-2.68.65-.75,1.52-1.29,2.49-1.54.97-.25,1.99-.2,2.93.15Z"
                  />
                  <path
                    fill="#dc3223"
                    d="M316.56,202.37c5.12,1.8,6.02,21.35,6.02,21.35-6.02-3.31-13.24,2.1-13.24,2.1,0,0-1.51-5.11-3.31-11.73-.55-1.86-.61-3.83-.19-5.72.42-1.89,1.32-3.65,2.6-5.1,0,0,3.01-2.71,8.13-.9Z"
                  />
                  <path
                    fill="1C2434"
                    d="M310.57,183.21c-1.43-1.15-3.39.94-3.39.94l-1.15-10.32s-7.17.86-11.77-.29c-4.59-1.15-5.31,4.16-5.31,4.16-.24-2.14-.29-4.3-.14-6.45.29-2.58,4.02-5.16,10.62-6.88,6.6-1.72,10.04,5.74,10.04,5.74,4.59,2.29,2.53,14.26,1.1,13.11Z"
                  />
                  <g>
                    <path
                      fill="#dc3223"
                      d="M167.94,104.87c-.28.33-.5.58-.75.88-.33-.13-.65-.24-.96-.36-9.22-3.75-19.83.34-23.98,9.23-3.12,6.69-1.71,14.73,3.49,19.94.19.19.41.37.77.69.5-.63.97-1.21,1.45-1.8.4.32.67.53.95.74,6.61,5,16.29,1.74,18.39-6.19,1.65-6.21-2.45-12.64-8.86-13.89-5.97-1.17-11.79,2.19-13.52,7.83-.3.98-.43,2.02-.66,3.15-.23.02-.55.05-.97.08-.06-.4-.14-.73-.16-1.06-.28-4.03,1.03-7.54,3.62-10.58,5.25-6.18,14.95-7.01,21.21-1.85.19.16.36.34.59.55-.36.42-.7.81-1.05,1.22.19.22.35.41.53.59,8.76,9.06,5.82,23.74-5.83,28.58-6.78,2.81-13.2,1.93-19-2.71-.22-.18-.44-.36-.66-.53-.02-.02-.06-.03-.19-.08-.52.5-1.08,1.02-1.66,1.58-.2-.18-.37-.31-.52-.46-5.02-5.09-7.41-11.23-6.54-18.31,1.14-9.33,6.44-15.63,15.2-18.86,6.15-2.27,12.27-1.79,18.16,1.13.3.15.59.32.96.53Z"
                    />
                    <path
                      fill="#dc3223"
                      d="M174.84,114.39c.71-.75,1.38-1.44,2.1-2.19,1,1.61,1.67,3.26,2.11,5,1.3,5.16.62,10.08-1.93,14.75-.3.56-.63.93-1.43.64,3.03-6.17,2.65-12.21-.85-18.19Z"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>

          <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
              <span className="mb-1.5 block font-medium">
                By Dream Services
              </span>
              <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                CSE Alstom VPF
              </h2>

              <form action={'#'}>
                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    Adresse Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      name={'email'}
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />

                    <span className="absolute right-4 top-4">
                      <svg
                        className="fill-current"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>

                {
                  hasAlreadyGetPasswordResetKey ? (
                      <>
                        <div className="mb-6">
                          <label className="mb-2.5 block font-medium text-black dark:text-white">
                            Votre clé de réinitialisation (reçu par email)
                          </label>
                          <div className="relative">
                            <input
                                type="text"
                                name={'secureKey'}
                                value={secureKey}
                                onChange={(e) => setSecureKey(e.target.value)}
                                placeholder="Votre clé de sécurité"
                                className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />

                            <span className="absolute right-4 top-4">
                      <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                          />
                          <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                          />
                        </g>
                      </svg>
                    </span>
                          </div>
                        </div>
                        <div className="mb-6">
                          <label className="mb-2.5 block font-medium text-black dark:text-white">
                            Votre Nouveau Mot de passe
                          </label>
                          <div className="relative">
                            <input
                                type="password"
                                name={'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="6+ Caractères, 1 Lettre en majuscule"
                                className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />

                            <span className="absolute right-4 top-4">
                      <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                          />
                          <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                          />
                        </g>
                      </svg>
                    </span>
                          </div>
                        </div>
                      </>
                  ) : null
                }

                <div className="mb-6">
                  <input type="checkbox" name={"gotkey"} value={String(hasAlreadyGetPasswordResetKey)} onChange={(e) => {
                    setHasAlreadyGetPasswordResetKey(e.target.checked);
                  }} className={
                    "mr-2 cursor-pointer rounded-lg border border-stroke bg-transparent p-4 text-black transition hover:bg-opacity-90 dark:border-strokedark dark:text-white"
                  }/>
                  <label htmlFor="gotkey">Avez vous déjà votre code de réinitialisation ?</label>
                </div>

                <div className="mb-5">
                  <input
                    type="submit"
                    value={hasAlreadyGetPasswordResetKey ? "Réinitialiser le mot de passe" : "Envoyer le code de réinitialisation"}
                    className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                    onClick={handleSendResetPassword}
                    id="form-send-reset-password" data-umami-event="ResetPassword"
                    disabled={loading}
                  />
                </div>

                {/* <div className="mt-6 text-center">
                  <p>
                    Vous n'avez pas de compte ?{' '}
                    <Link to="/register" className="text-primary">
                      S'enregistrer
                    </Link>
                  </p>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaasswordReset;
