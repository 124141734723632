/*const config = {
        backendUrl: "https://alstom-vpf-srv.club-dreamservices.com",
        frontEndUrl: "https://alstom-vpf.club-dreamservices.com",
        shop_id: 203, //113 // ID de la boutique alstom vpf 101167 julien 203
        appwrite_endpoint: "https://dreamservices-paas.jud3v.fr/v1",
        appwrite_project_id: "6712ef5800258c087044",
        appwrite_secret: "standard_66a646f026cf347deae38be2d886adb819ad90b03e6b0baaa73c558178b390038a97c874ee30531743fe546f74fbc7b47b885fa1fb46ef35ace4b023f903ed7495053ae389f4bb89314935455d426926081d3905c8963ebbe2e6434c53627f27437b6ab4e4fa8c48d1ec562a862d3a9740634caeed72d8cab9a84da6e7386ac1"
}*/

const config = {
        backendUrl: "https://alstom-vpf-srv.club-dreamservices.com",
        frontEndUrl: "https://alstom-vpf.club-dreamservices.com",
        shop_id: 203, //203 // ID de la boutique alstom vpf 101167 julien 203
        appwrite_endpoint: "https://dreamservices-paas.jud3v.fr/v1",
        appwrite_project_id: "6712ef5800258c087044",
        appwrite_secret: "standard_66a646f026cf347deae38be2d886adb819ad90b03e6b0baaa73c558178b390038a97c874ee30531743fe546f74fbc7b47b885fa1fb46ef35ace4b023f903ed7495053ae389f4bb89314935455d426926081d3905c8963ebbe2e6434c53627f27437b6ab4e4fa8c48d1ec562a862d3a9740634caeed72d8cab9a84da6e7386ac1",
        umamiSiteKey: "59a9b8e6-8648-435f-b6b4-089757b61857",
        dev: false
}

export default config