const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-dsbackground dark:darkdsbackground">
      <div
        className="h-16 w-16 animate-spin"
        style={{ animationDirection: 'reverse' }}
      >
        <svg
          id="Mode_Isolation"
          data-name="Mode Isolation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 491.03 450.46"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '\n      .cls-1 {\n        fill: #dc3223;\n      }\n    ',
              }}
            />
          </defs>
          <path
            className="cls-1"
            d="M366.35,32.75c-2.99,3.5-5.29,6.2-7.94,9.31-3.51-1.33-6.88-2.53-10.18-3.87-97.87-39.78-210.63,3.61-254.69,98.01-33.15,71.03-18.2,156.47,37.09,211.73,2.06,2.06,4.35,3.9,8.22,7.34,5.34-6.64,10.34-12.85,15.36-19.09,4.3,3.35,7.14,5.63,10.04,7.82,70.16,53.09,173.03,18.46,195.33-65.76,17.48-66-26.03-134.21-94.09-147.5-63.39-12.38-125.25,23.23-143.62,83.12-3.2,10.42-4.52,21.42-6.97,33.42-2.4.21-5.85.51-10.26.89-.67-4.25-1.48-7.73-1.72-11.26-3.01-42.84,10.93-80.04,38.41-112.35,55.8-65.6,158.82-74.46,225.26-19.62,2.02,1.67,3.85,3.56,6.3,5.84-3.81,4.42-7.4,8.58-11.2,12.98,2.07,2.32,3.76,4.34,5.58,6.23,93,96.26,61.86,252.1-61.96,303.47-71.97,29.85-140.21,20.5-201.83-28.77-2.34-1.87-4.64-3.79-6.97-5.67-.25-.2-.6-.28-1.99-.89-5.54,5.26-11.42,10.86-17.61,16.75-2.17-1.91-3.98-3.31-5.57-4.93C17.99,355.89-7.32,290.66,1.84,215.46,13.92,116.37,70.21,49.43,163.23,15.14c65.3-24.07,130.29-18.97,192.91,12.02,3.21,1.59,6.29,3.44,10.21,5.6Z"
          />
          <path
            className="cls-1"
            d="M439.56,133.87c7.59-7.94,14.65-15.32,22.26-23.29,10.61,17.14,17.76,34.65,22.4,53.1,13.76,54.84,6.54,107.01-20.48,156.61-3.24,5.94-6.74,9.86-15.14,6.75,32.22-65.47,28.09-129.67-9.04-193.18Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
