import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import DefaultLayout from './layout/DefaultLayout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.css';
import PasswordReset from './pages/Authentication/PasswordReset.tsx';

const SignIn = lazy(() => import('./pages/Authentication/SignIn'));
const Calendar = lazy(() => import('./pages/Calendar'));
const ECommerce = lazy(() => import('./pages/Dashboard/ECommerce'));
const FormElements = lazy(() => import('./pages/Form/FormElements'));
const Clients = lazy(() => import('./pages/Clients.tsx'));
const Product = lazy(() => import('./pages/Product.tsx'));
const Category = lazy(() => import('./pages/Category.tsx'));
const Order = lazy(() => import('./pages/Order.tsx'));
const Rules = lazy(() => import('./pages/Rules.tsx'));
const Settings = lazy(() => import('./pages/Settings'));
const CreateOrder = lazy(() => import('./pages/OrderCreate.tsx'));
const ProductCreate = lazy(() => import('./pages/ProductCreate.tsx'));
const OrderDetailsLoader = lazy(() => import('./pages/OrderDetailsLoader.tsx'));
const InvoicesCreateLoader = lazy(
  () => import('./pages/InvoicesCreateLoader.tsx'),
);
const InvoiceViewLoader = lazy(() => import('./pages/InvoiceViewLoader.tsx'));
const Documentation = lazy(() => import('./pages/Documentation.tsx'));
const RulesAdd = lazy(() => import('./pages/RulesAdd.tsx'));
const RulesEdit = lazy(() => import('./pages/RulesEdit.tsx'));
const BankAccountLoader = lazy(() => import('./pages/BankAccountLoader.tsx'));
const BankAccountEdit = lazy(() => import('./pages/BankAccountEdit.tsx'));
const BankAccountCreate = lazy(() => import('./pages/BankAccountCreate.tsx'));
const FacturationLoader = lazy(() => import('./pages/FacturationLoader.tsx'));
const AddressFacturationCreate = lazy(
  () => import('./pages/AddressFacturationCreate.tsx'),
);
const AddressFacturationEdit = lazy(
  () => import('./pages/AddressFacturationEdit.tsx'),
);
const PaymentMethodLoader = lazy(
  () => import('./pages/paymentMethod/loader.tsx'),
);
const PaymentMethodEdit = lazy(() => import('./pages/paymentMethod/edit.tsx'));
const PaymentMethodCreate = lazy(
  () => import('./pages/paymentMethod/create.tsx'),
);
const ShippingMethodLoader = lazy(
  () => import('./pages/shippingMethod/loader.tsx'),
);
const StockMovementLoader = lazy(
  () => import('./pages/StockMovement/loader.tsx'),
);
const ShippingMethodCreate = lazy(
  () => import('./pages/shippingMethod/create.tsx'),
);
const ShippingMethodEdit = lazy(
  () => import('./pages/shippingMethod/edit.tsx'),
);
const ProductAlertLoader = lazy(
  () => import('./pages/productAlert/loader.tsx'),
);
const ProductAlertCreate = lazy(
  () => import('./pages/productAlert/create.tsx'),
);
const ProductAlertEdit = lazy(() => import('./pages/productAlert/edit.tsx'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            index
            element={
              <>
                <PageTitle title="Alstom Dashboard | Page d'accueil" />
                <ECommerce />
              </>
            }
          />
          <Route
            path="/documentation"
            element={
              <>
                <PageTitle title="Documentation" />
                <Documentation />
              </>
            }
          />
          <Route
            path="/stock-movements"
            element={
              <>
                <PageTitle title="Mouvements de stock" />
                <StockMovementLoader />
              </>
            }
          />
          <Route
            path="/clients/:id/orders"
            element={
              <>
                <PageTitle title="Commande Clients | Liste des comandes clients" />
                <Calendar />
              </>
            }
          />
          <Route
            path="/clients"
            element={
              <>
                <PageTitle title="Clients | Retrouvez les clients de votre CSE" />
                <Clients />
              </>
            }
          />
          <Route
            path="/clients/quota"
            element={
              <>
                <PageTitle title="Quotas clients | Vérifier que le clients à bien respecter les quotas" />
                <FormElements />
              </>
            }
          />
          {/* CONFIGURATION ROUTES */}
          <Route
            path="/configuration/settings"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos paramètres utilisateurs" />
                <Settings />
              </>
            }
          />
          <Route
            path="/configuration/bank"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos paramètres bancaires" />
                <BankAccountLoader />
              </>
            }
          />
          <Route
            path="/configuration/bank/create"
            element={
              <>
                <PageTitle title="Paramètre | Création d'une nouvelle bank" />
                <BankAccountCreate />
              </>
            }
          />
          <Route
            path="/password-reset"
            element={
              <>
                <PageTitle title="Mise à jour de votre mots de passe utilisateur" />
                <PasswordReset />
              </>
            }
          />
          <Route
            path="/configuration/bank-edit/:id"
            element={
              <>
                <PageTitle title="Paramètre | Modification de la banque" />
                <BankAccountEdit />
              </>
            }
          />
          <Route
            path="/configuration/shipping"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de livraison" />
                <ShippingMethodLoader />
              </>
            }
          />
          <Route
            path="/configuration/shipping-create"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de livraison" />
                <ShippingMethodCreate />
              </>
            }
          />
          <Route
            path="/configuration/shipping/:id"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de livraison" />
                <ShippingMethodEdit />
              </>
            }
          />
          <Route
            path="/configuration/payment-method"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de paiement" />
                <PaymentMethodLoader />
              </>
            }
          />
          <Route
            path="/configuration/payment-method/create"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de paiement" />
                <PaymentMethodCreate />
              </>
            }
          />
          <Route
            path="/configuration/payment-method/:id"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos méthode de paiement" />
                <PaymentMethodEdit />
              </>
            }
          />
          <Route
            path="/configuration/products-alerts"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos alertes" />
                <ProductAlertLoader />
              </>
            }
          />
          <Route
            path="/configuration/product-alert/create"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos alertes" />
                <ProductAlertCreate />
              </>
            }
          />
          <Route
            path="/configuration/product-alert/:id"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos alertes" />
                <ProductAlertEdit />
              </>
            }
          />
          <Route
            path="/configuration/facturation"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos adresse de facturation" />
                <FacturationLoader />
              </>
            }
          />
          <Route
            path="/configuration/facturation-edit/:id"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos adresse de facturation" />
                <AddressFacturationEdit />
              </>
            }
          />
          <Route
            path="/configuration/facturation/create"
            element={
              <>
                <PageTitle title="Paramètre | Veuillez définir vos adresse de facturation" />
                <AddressFacturationCreate />
              </>
            }
          />
          {/* CONFIGURATION ROUTES */}
          <Route
            path="/products"
            element={
              <>
                <PageTitle title="Produits | Afficher les produits disponible depuis votre boutique CSE" />
                <Product />
              </>
            }
          />
          <Route
            path="/create-order"
            element={
              <>
                <PageTitle title="Créer un commande | Créer une commande client et/ou CSE" />
                <CreateOrder />
              </>
            }
          />
          <Route
            path="/categories"
            element={
              <>
                <PageTitle title="Catégorie | Afficher les catégories disponible dans votre boutique CSE" />
                <Category />
              </>
            }
          />
          <Route
            path="/rules"
            element={
              <>
                <PageTitle title="Règle de prix | Vérifier en un coup d'œil que les règle pré-définis sont correcte" />
                <Rules />
              </>
            }
          />
          <Route
            path="/rules/add"
            element={
              <>
                <PageTitle title="Ajouter Une Règle de prix | Ajouter aisément des règle interne de vente" />
                <RulesAdd />
              </>
            }
          />
          <Route
            path="/rules/edit/:id"
            element={
              <>
                <PageTitle title="Modifier Une Règle de prix | Modifier facilement vos règle de prix" />
                <RulesEdit />
              </>
            }
          />
          <Route
            path="/invoices"
            element={
              <>
                <PageTitle title="Gestion des factures Alstom VPF" />
                <InvoicesCreateLoader />
              </>
            }
          />
          <Route
            path="/invoices-create"
            element={
              <>
                <PageTitle title="Création des factures Alstom VPF" />
                <InvoicesCreateLoader />
              </>
            }
          />
          <Route
            path="/invoice/:id"
            element={
              <>
                <PageTitle title="Création des factures Alstom VPF" />
                <InvoiceViewLoader />
              </>
            }
          />
          <Route
            path="/order"
            element={
              <>
                <PageTitle title="Gestion des commandes Alstom VPF" />
                <Order />
              </>
            }
          />
          <Route
            path="/order-create"
            element={
              <>
                <PageTitle title="Création des commandes Alstom VPF" />
                <CreateOrder />
              </>
            }
          />
          <Route
            path="/order-details/:id"
            element={
              <>
                <PageTitle title="Création des commandes Alstom VPF" />
                <OrderDetailsLoader />
              </>
            }
          />
          <Route
            path="/products-create"
            element={
              <>
                <PageTitle title="Création de produit Altom VPF" />
                <ProductCreate />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <PageTitle title="Connexion Dashboard Alstom | Page de connexion utilisateur" />
                <SignIn />
              </>
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        limit={3}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        toastClassName="shadcn-toast"
        bodyClassName="shadcn-toast-body"
      />
    </DefaultLayout>
  );
}

export default App;
