import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LuPackage } from 'react-icons/lu';
import { BiSolidCategory } from 'react-icons/bi';
import { SiAdblock } from 'react-icons/si';
import {
  FaAddressBook,
  FaCog,
  FaUserAstronaut,
  FaUserCog,
} from 'react-icons/fa';
import { BiSolidDashboard } from 'react-icons/bi';
import { IoTicketSharp } from 'react-icons/io5';
import { IoDocumentTextOutline } from 'react-icons/io5';
import LogoWhite from '../../images/logo/alstom-Logo.white.png';
import Logo from '../../images/logo/alstom-Logo.wine.png';
import SidebarLinkGroup from './SidebarLinkGroup';
import React from 'react';
import { IoIosNotifications } from 'react-icons/io';
import { MdOutlineLocalShipping, MdOutlinePayment } from 'react-icons/md';
import { TbTransfer } from 'react-icons/tb';
import { User } from '../../types';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  );

  const [user, setUser] = useState<User | null>(() => {
    const user = localStorage.getItem('sessionUser');
    if (user) {
      return JSON.parse(user)?.state?.user;
    }
    return null;
  });

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  const configurationActive =
  pathname === '/configuration' ||
  (pathname.includes('configuration') && !pathname.includes('configuration/settings')) ||
  pathname === '/products' ||
  pathname === '/rules';

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-dsgray-base duration-300 ease-linear dark:bg-darkdsgray-base border-r border-gray-200 dark:border-gray-900 shadow-lg shadow-gray-800/50 lg:static lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/" id="navigate-home" data-umami-event="user navigate home">
          <img
            src={Logo}
            alt="Alstom Logo"
            className="mt-4 pl-4 h-28 w-auto dark:hidden"
          />
          <img
            src={LogoWhite}
            alt="Alstom Logo White"
            className="mt-4 pl-4 h-28 w-auto hidden dark:block"
          />
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden mt-4"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-dstext dark:text-darkdstext dark:text-darkdstext">
              MENU
            </h3>

            <ul className="mb-6 flex flex-col gap-1.5">
              {/* <!-- Menu Item Dashboard --> */}

              {/* <!-- Menu Item Calendar --> */}
              {/*<li>
                <NavLink
                  to="/calendar"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('calendar') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7499 2.9812H14.2874V2.36245C14.2874 2.02495 14.0062 1.71558 13.6405 1.71558C13.2749 1.71558 12.9937 1.99683 12.9937 2.36245V2.9812H4.97803V2.36245C4.97803 2.02495 4.69678 1.71558 4.33115 1.71558C3.96553 1.71558 3.68428 1.99683 3.68428 2.36245V2.9812H2.2499C1.29365 2.9812 0.478027 3.7687 0.478027 4.75308V14.5406C0.478027 15.4968 1.26553 16.3125 2.2499 16.3125H15.7499C16.7062 16.3125 17.5218 15.525 17.5218 14.5406V4.72495C17.5218 3.7687 16.7062 2.9812 15.7499 2.9812ZM1.77178 8.21245H4.1624V10.9968H1.77178V8.21245ZM5.42803 8.21245H8.38115V10.9968H5.42803V8.21245ZM8.38115 12.2625V15.0187H5.42803V12.2625H8.38115ZM9.64678 12.2625H12.5999V15.0187H9.64678V12.2625ZM9.64678 10.9968V8.21245H12.5999V10.9968H9.64678ZM13.8374 8.21245H16.228V10.9968H13.8374V8.21245ZM2.2499 4.24683H3.7124V4.83745C3.7124 5.17495 3.99365 5.48433 4.35928 5.48433C4.7249 5.48433 5.00615 5.20308 5.00615 4.83745V4.24683H13.0499V4.83745C13.0499 5.17495 13.3312 5.48433 13.6968 5.48433C14.0624 5.48433 14.3437 5.20308 14.3437 4.83745V4.24683H15.7499C16.0312 4.24683 16.2562 4.47183 16.2562 4.75308V6.94683H1.77178V4.75308C1.77178 4.47183 1.96865 4.24683 2.2499 4.24683ZM1.77178 14.5125V12.2343H4.1624V14.9906H2.2499C1.96865 15.0187 1.77178 14.7937 1.77178 14.5125ZM15.7499 15.0187H13.8374V12.2625H16.228V14.5406C16.2562 14.7937 16.0312 15.0187 15.7499 15.0187Z"
                      fill=""
                    />
                  </svg>
                  Calendar
                </NavLink>
              </li>*/}
              {/* <!-- Menu Item Calendar --> */}

              {/* <!-- Menu Item Profile --> */}
              <li>
                <NavLink
                    id="navigate-home-2" data-umami-event="user navigate home2"
                  to="/"
                  className={`group relative flex items-center gap-2.5  py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname === '/' &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <BiSolidDashboard />
                  Accueil
                </NavLink>
              </li>
              <li>
                <NavLink
                    id="navigate-salary" data-umami-event="user navigate salary list"
                  to="/clients"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/clients') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <FaUserAstronaut />
                  Vos Salariés
                </NavLink>
              </li>
              {/* <!-- Menu Item Profile --> */}
              <li>
                <NavLink
                  to="/categories"
                  id="navigate-categories" data-umami-event="user navigate categories list"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/categories') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <BiSolidCategory />
                  Vos catégories
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/order"
                  id="navigate-order" data-umami-event="user navigate order"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/order') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <IoTicketSharp />
                  Gestion commandes
                </NavLink>
              </li>
              {/*<li>
                <NavLink
                  to="/invoices"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/invoices') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <FaFileInvoice />
                  Gestion des factures
                </NavLink>
              </li>*/}
              <li>
                <NavLink
                  to="/stock-movements"
                  id="navigate-stock-movement" data-umami-event="user navigate stock movements"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/stock-movements') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <TbTransfer />
                  Mouvement de stock
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/configuration/settings"
                  id="navigate-configuration settings" data-umami-event="user navigate configuration settings"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('configuration/settings') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <FaUserCog />
                  Réglage Utilisateur
                </NavLink>
              </li>

              {/* <!-- Menu Item Forms --> */}
              {user &&
                (user?.role === 'admin' || user?.role === 'super-admin') && (
                  <SidebarLinkGroup
                    activeCondition={
                      pathname === '/forms' || pathname.includes('forms')
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                         <NavLink
                            to="#"
                            id="configuration-menu"
                            className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                              configurationActive && 'bg-dssecondary-active dark:bg-darkdssecondary-active'
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                            }}
                          >
                            <FaCog />
                            Configuration
                            <svg
                              className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${open && 'rotate-180'}`}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                                fill=""
                              />
                            </svg>
                          </NavLink>
                          <div
                            className={`translate transform overflow-hidden ${
                              !open && 'hidden'
                            }`}
                          >
                            <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                              <li>
                              <NavLink
                                to="/products"
                                id="navigate-product" data-umami-event="user navigate product list"
                                className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                  /^\/products(\/|$)/.test(pathname) &&
                                  'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                }`}
                              >
                                <LuPackage />
                                Gestion produits
                              </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/rules"
                                  id="navigate-subvention-product" data-umami-event="user navigate subvention product list"
                                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                    pathname.includes('/rules') &&
                                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                  }`}
                                >
                                  <SiAdblock />
                                  Subvention produits
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/configuration/facturation"
                                  id="navigate-facturation" data-umami-event="user navigate facturation"
                                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                    pathname.includes(
                                      'configuration/facturation',
                                    ) &&
                                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                  }`}
                                >
                                  <FaAddressBook />
                                  Adresse Facturation
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/configuration/shipping"
                                  id="navigate-shipping-method" data-umami-event="user navigate shipping method"
                                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                    pathname.includes(
                                      'configuration/shipping',
                                    ) &&
                                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                  }`}
                                >
                                  <MdOutlineLocalShipping />
                                  Méthode Livraison
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/configuration/payment-method"
                                  id="navigate-payment-method" data-umami-event="user navigate payment method"
                                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                    pathname.includes(
                                      'configuration/payment-method',
                                    ) &&
                                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                  }`}
                                >
                                  <MdOutlinePayment />
                                  Méthode Paiement
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/configuration/products-alerts"
                                  id="navigate-alert-product" data-umami-event="user navigate alert product"
                                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                                    pathname.includes(
                                      'configuration/products-alerts',
                                    ) &&
                                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                                  }`}
                                >
                                  <IoIosNotifications />
                                  Alerte Produit
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
              {/* <!-- Menu Item Forms --> */}

              {/* <!-- Menu Item Tables --> */}
              {/*<li>
                <NavLink
                  to="/tables"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('tables') && 'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_130_9756)">
                      <path
                        d="M15.7501 0.55835H2.2501C1.29385 0.55835 0.506348 1.34585 0.506348 2.3021V15.8021C0.506348 16.7584 1.29385 17.574 2.27822 17.574H15.7782C16.7345 17.574 17.5501 16.7865 17.5501 15.8021V2.3021C17.522 1.34585 16.7063 0.55835 15.7501 0.55835ZM6.69385 10.599V6.4646H11.3063V10.5709H6.69385V10.599ZM11.3063 11.8646V16.3083H6.69385V11.8646H11.3063ZM1.77197 6.4646H5.45635V10.5709H1.77197V6.4646ZM12.572 6.4646H16.2563V10.5709H12.572V6.4646ZM2.2501 1.82397H15.7501C16.0313 1.82397 16.2563 2.04897 16.2563 2.33022V5.2271H1.77197V2.3021C1.77197 2.02085 1.96885 1.82397 2.2501 1.82397ZM1.77197 15.8021V11.8646H5.45635V16.3083H2.2501C1.96885 16.3083 1.77197 16.0834 1.77197 15.8021ZM15.7501 16.3083H12.572V11.8646H16.2563V15.8021C16.2563 16.0834 16.0313 16.3083 15.7501 16.3083Z"
                        fill=""
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_130_9756">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(0 0.052124)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Tables
                </NavLink>
              </li>*/}
              <li>
                <NavLink
                  to="https://docs.alstom.club-dreamservices.com"
                  target="_blank"
                  id="navigate-documentation" data-umami-event="user navigate documentation"
                  className={`group relative flex items-center gap-2.5 py-2 px-4 font-medium text-dstext dark:text-darkdstext duration-300 ease-in-out hover:bg-dssecondary-active rounded-3xl dark:hover:bg-darkdssecondary-active ${
                    pathname.includes('/documentation') &&
                    'bg-dssecondary-active dark:bg-darkdssecondary-active'
                  }`}
                >
                  <IoDocumentTextOutline />
                  Documentation{' '}
                  <span className="absolute right-4 top-1/2 -translate-y-1/2 fill-current">
                    <FaArrowUpRightFromSquare />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
