import {driver} from "driver.js";

export const basicTutorial = driver({
    showProgress: true,
    animate: true,
    allowClose: true,
    allowKeyboardControl: true,
    nextBtnText: 'Suivant',
    prevBtnText: 'Précédent',
    steps: [
        // Sidebar ids
        { element: '#dashboard', popover: { title: "Page d'accueil", description: 'Accéder à la page d\'accueil.' } },
        { element: '#clients', popover: { title: "Gestion des salariés", description: 'Accéder à la page de gestion des salariés. En général, cette page vous servira à rechercher un matricule afin de récupérer l\'ID client Dreamservices.' } },
        { element: '#categories', popover: { title: "Visualisation des catégories", description: 'Accéder à la page des catégories disponibles depuis la billetterie en ligne.' } },
        { element: '#products', popover: { title: "Gestion des produits", description: 'Accéder à cette page afin de gérer votre billetterie en interne.' } },
        { element: '#order', popover: { title: "Gestion des commandes", description: 'Cette page fait référence aux commandes créées par les administrateurs internes pour les salariés. Vous pouvez créer et modifier une commande depuis cette page.' } },
        { element: '#stock-movements', popover: { title: "Mouvements des quantités disponibles", description: 'Cette page permet de voir graduellement les différents mouvements des stocks, que ce soit l\'ajout ou encore le retrait lors de commandes.' } },
        { element: '#rules', popover: { title: "Gestion des règles internes", description: 'Accéder à la page de gestion des règles internes pour les produits internes et les commandes externes.' } },
        { element: '#documentation', popover: { title: "Page de documentation", description: 'Cette page est en cours de développement et sera complétée à l\'avenir.' } },
        { element: '#configuration-menu', popover: { title: "Menu de configuration", description: 'Appuyez sur ce menu afin de dérouler les différents choix de configuration possibles.' } },
        { element: '#bank', popover: { title: "Gestion des comptes bancaires", description: 'Accéder à la création et à la modification des comptes bancaires.' } },
        { element: '#facturation', popover: { title: "Gestion des adresses de facturation", description: 'Accéder à la création et à la modification des adresses de facturation.' } },
        { element: '#shipping', popover: { title: "Gestion des méthodes de livraison", description: 'Accéder à la création et à la modification des méthodes de livraison.' } },
        { element: '#payment-method', popover: { title: "Gestion des méthodes de paiement", description: 'Accéder à la création et à la modification des méthodes de paiement.' } },
        { element: '#products-alerts', popover: { title: "Gestion des alertes produits", description: 'Accéder à la création et à la modification des alertes produits.' } },
        { element: '#settings', popover: { title: "Gestion des paramètres", description: 'Accéder à la création et à la modification des paramètres de votre compte (en cours de développement).' } },
    ]
})